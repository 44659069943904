<nav *ngIf="nav.visible"
    class="navbar navbar-toggler justify-content-center align-items-center fixed-top navbar-expand-xl navbar-light bg-light mb-5 col-12">
    <!-- [ngClass]="{ 'col-10':homePage, 'col-12':!homePage }"> -->

    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-list fa-2x"></i>
    </button>
    <div *ngIf="isUserLoggedIn" class="row nav-item username mobile"
        [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
        <div class="text-right list-div">
            <div class="dropdown show">
                <div id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="dropdown-toggle"
                    title="{{authenticationService.userData.firstname}} {{authenticationService.userData.lastname}}">
                    <p>{{authenticationService.userData.firstname}} {{authenticationService.userData.lastname}} </p>
                </div>
                <div class="dropdown-menu text-right" aria-labelledby="dropdownMenuLink">
                    <!-- <a *ngIf="!profilePassRequired" routerLink="/user-profile" class="dropdown-item" href="#">{{
                        'app.dropdown.profile' | translate }}</a>
                    <a *ngIf="profilePassRequired" (click)="openPassModal(passModal)" class="dropdown-item">{{
                        'app.dropdown.profile' | translate }}</a> -->
                    <a (click)="logout('diff')" class="dropdown-item">{{
                        'app.dropdown.switch-user' | translate
                        }}</a>
                    <a (click)="logout('logout')" class="dropdown-item">{{
                        'app.dropdown.logout-user' | translate
                        }}</a>
                    <a class="dropdown-item" (click)="logout('exit')">{{ 'app.dropdown.exit' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="!isMobile" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }" class="row nav-item lang">
        <select class="form-control selectpicker" (change)="useLanguage($event.target.value)">
            <option value="he" [selected]='this.translateService.currentLang == "he"'>עברית</option>
            <option value="en" [selected]='this.translateService.currentLang == "en"'>English</option>
        </select>
    </div> -->
    <div class="brand-div">
        <a class="navbar-brand" href="#" routerLink="/main"><img src="../assets/images/new/logo.png" id="nativ-logo"
                (click)="active('main')" /></a>
    </div>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="nav navbar-nav">
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <div *ngIf="!isMobile" class="row nav-item date main">{{heDate}} <br />
                    {{date}}
                    <br />
                    <div *ngIf="displayFrTel">
                        {{frTel}}
                    </div>
                </div>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" routerLink="/main" (click)="active('main')" href="#"
                    [ngClass]="{ 'active':activeTab==='main'}">{{ 'app.home' |
                    translate
                    }}<span class="mobile"> &#10095;&#10095;</span></a>
            </li>

            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" href="#" routerLink="/kosher" (click)="active('kosher')"
                    [ngClass]="{ 'active':activeTab==='kosher'}">{{ 'app.kosher' | translate }}<span class="mobile">
                        &#10095;&#10095;</span></a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" href="#" routerLink="/contact" (click)="active('contact')"
                    [ngClass]="{ 'active':activeTab==='contact'}">{{ 'app.contact' |
                    translate }}<span class="mobile">
                        &#10095;&#10095;</span></a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" href="#" routerLink="/support" (click)="active('support')"
                    [ngClass]="{ 'active':activeTab==='support'}">{{ 'app.support' |
                    translate }}<span class="mobile">
                        &#10095;&#10095;</span></a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" href="#" routerLink="/about" (click)="active('about')"
                    [ngClass]="{ 'active':activeTab==='about'}">{{ 'app.about' | translate
                    }}<span class="mobile">
                        &#10095;&#10095;</span></a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a *ngIf="!profilePassRequired || router.url=='/user-profile'" (click)="navigateUserProfile()"
                    class="nav-link" (click)="active('user-profile')"
                    [ngClass]="{ 'active':activeTab==='user-profile'}">{{
                    'app.dropdown.profile' | translate }}
                    <span class="mobile"> &#10095;&#10095;</span>
                </a>
                <a *ngIf="profilePassRequired && router.url!=='/user-profile'" (click)="openPassModal(passModal)"
                    [ngClass]="{ 'active':activeTab==='user-profile'}" class="nav-link"
                    (click)="active('user-profile')">{{
                    'app.dropdown.profile' | translate }}
                    <span class="mobile"> &#10095;&#10095;</span>
                </a>
            </li>
            <li class="nav-item" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <a class="nav-link" href="#" routerLink="/news" (click)="active('news')"
                    [ngClass]="{ 'active':activeTab==='news'}">{{ 'app.news' | translate
                    }}<span class="mobile">
                        &#10095;&#10095;</span></a>
            </li>
            <!-- <li routerLink="/mails" *ngIf="isUserLoggedIn" class="row nav-item main" (click)="clearMailsCount()">
        <div class="col-md-4 link">
          <a class="nav-link" href="#" routerLink="/mails">{{ 'app.mail' | translate }}</a>
        </div>
        <div *ngIf="pendingMsgsCount > 0" class="col-md-6 link">
          <span class="msg-cnt">{{pendingMsgsCount}}</span>
        </div>
      </li> -->
            <li *ngIf="!isUserLoggedIn && !isMobile && router.url !== '/main'" class="nav-item button not-mobile"
                [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <button [routerLink]="['/sales']" routerLinkActive="active" id="login-btn" class="btn-nativ btn-sm">{{
                    'app.join' | translate }} &#10095;&#10095;</button>
            </li>
            <li *ngIf="isUserLoggedIn" class="row nav-item username not-mobile"
                [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }">
                <div id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="col-md-8 name-div dropdown-toggle"
                    title="{{authenticationService.userData.firstname}} {{authenticationService.userData.lastname}}">
                    <p>{{authenticationService.userData.firstname}} {{authenticationService.userData.lastname}} </p>
                </div>
                <div class="col-md-4 text-right list-div">
                    <div class="dropdown show">
                        <a class="dropdown-toggle" href="#" id="dropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-list"></i>
                        </a>
                        <div class="dropdown-menu text-right" aria-labelledby="dropdownMenuLink">
                            <!-- <a *ngIf="!profilePassRequired" routerLink="/user-profile" class="dropdown-item" href="#">{{
                                'app.dropdown.profile' | translate
                                }}</a>
                            <a *ngIf="profilePassRequired" (click)="openPassModal(passModal)" class="dropdown-item"
                                id="profile-tab">{{
                                'app.dropdown.profile' | translate }}</a> -->
                            <a (click)="logout('diff')" class="dropdown-item">{{
                                'app.dropdown.switch-user' | translate
                                }}</a>
                            <a (click)="logout('logout')" class="dropdown-item">{{
                                'app.dropdown.logout-user' | translate
                                }}</a>
                            <a class="dropdown-item" (click)="logout('exit')">{{ 'app.dropdown.exit' |
                                translate
                                }}</a>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
    <div *ngIf="!isMobile" [ngClass]="{ 'mainHomePage': homePage , 'main':!homePage }" class="row nav-item lang">
        <select class="form-control selectpicker" (change)="useLanguage($event.target.value)">
            <option value="he" [selected]='this.translateService.currentLang == "he"'>עב</option>
            <option value="en" [selected]='this.translateService.currentLang == "en"'>EN</option>
        </select>
    </div>
    <!-- <div *ngIf="!isMobile" class="row nav-item date main">{{heDate}} <br />
        {{date}}
        <br />
        <div *ngIf="displayFrTel">
            {{frTel}}
        </div>
    </div> -->
</nav>

<ng-template #passModal let-modal class="modal pass-modal">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-1 close-modal-header">
                <button type="button" class="close" (click)="close(modal)" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 dep-modal-header profile text-right">
                <div class="row">
                    <div class="col-3 pl-0 text-left">
                    </div>
                    <div class="col-9 pr-0">
                        <div class="not-mobile">{{ 'profile.password-modal.header' | translate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <form [formGroup]="syncPassForm" class="create-form text-center"
            (ngSubmit)="onSubmitPass(syncPassForm.value,modal)">
            <div class="row justify-content-center">
                <div class="col-md-10 input-div text-right">
                    <div class="form-group">
                        <label for="password">{{ 'profile.password-modal.input' | translate }}</label>
                        <div class="row">
                            <div class="row">
                                <input [type]="isPasswordVisible ? 'text' : 'password'" (focus)="onFocus()"
                                    formControlName="password" class="col-10 form-control" id="password"
                                    [ngClass]="{ 'is-invalid': submitted && fp.password.errors }">
                                <i [ngClass]="isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    class="col-2 eye-slash" (click)="togglePasswordVisibility()"></i>
                            </div>
                            <div *ngIf="submitted && fp.password.errors" class="invalid-feedback">
                                <div *ngIf="fp.password.errors.required">{{ 'form.validation.emptyPassword' | translate
                                    }} </div>
                                <div *ngIf="fp.password.errors && invalidPass">{{ 'form.validation.invalidPassword' |
                                    translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer justify-content-center">
                <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn">{{ 'global.enter'| translate
                    }}</button>
            </div>
        </form>
    </div>
</ng-template>